<template>
  <v-overlay :value="overlay">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppOverlay',
  computed: {
    ...mapState('ui', ['overlay']),
  },
}
</script>

<style scoped></style>
